import React from 'react';
import { graphql } from 'gatsby';

import { Layout, VideoThumbnail } from '../components';

const TutorialVideoPageTemplate = ({ data }) => {
    const video = data.markdownRemark.frontmatter;
    return (
        <Layout title={ video.title }>
            <div className='py-4 lg:py-8'>
                <div className='w-full px-4 mb-4 lg:mb-8 text-2xl lg:text-4xl text-center text-primary font-light'>{ video.title }</div>
                <div className='w-full px-4 md:px-0 md:w-4/5 lg:w-3/5 mx-auto'>
                    <VideoThumbnail { ...video } className='shadow-xl' size='large'/>
                </div>
            </div>
        </Layout>
    );
};

export default TutorialVideoPageTemplate;

export const pageQuery = graphql`
    query TutorialVideoPageTemplateQuery ($templateKey: String!, $title: String!) {
        markdownRemark(frontmatter: { templateKey: { eq: $templateKey }, title: { eq: $title } }) {
            frontmatter {
                thumbnail {
                    urlRemainder
                    sourceURL
                    deliveryType
                    cloudinaryMedia
                    assetType
                }
                video {
                    urlRemainder
                    sourceURL
                    deliveryType
                    cloudinaryMedia
                    assetType
                }
                title
            }
        }
    }
`;